
            @import "@/assets/sass/vars.portal.scss";
          




































































































































































































































































































































































































.v-invoice-detail {
    &__separator {
        td {
            border-top: 2px solid $gray-400 !important;
        }
    }
}
