
            @import "@/assets/sass/vars.portal.scss";
          


























































































































































































































































































































































.invoice-show-modal {
    table {
        tfoot {
            tr {
                background: $gray-400;
            }

            td {
                font-weight: bold;
                font-size: 18px;
            }
        }
    }
}
