
            @import "@/assets/sass/vars.portal.scss";
          





















































































































































































































































































































































































































































































































































































































































































































































































































































































.v-new-ready {
    .fee-regulation {
        border-bottom: 2px solid black;
    }
    .fee-balanced {
    }
}
